<template>
  <div class="main app-container">
    <div class="head">
      <div class="head-top">
        <div class="province screen-item">
          <div class="title">地区：</div>
          <div class="text">{{ infoData.ProvinceName }}</div>
        </div>
        <div class="year screen-item">
          <div class="title">年份：</div>
          <div class="text">{{ infoData.Year }}</div>
        </div>
        <div class="subjectType screen-item">
          <div class="text">{{ infoData.typeName }}</div>
        </div>
        <div class="screen-item">
          <el-button @click="modifyInfo" type="primary" size="mini"
            >修改</el-button
          >
        </div>
      </div>
      <div class="head-bottom">
        <div class="box2">
          <condition-screen
            @screenParameters="screenParameters"
            @searchSchool="searchSchool"
            ref="conditionScreen"
          ></condition-screen>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="content-table">
        <el-table
          :data="mainData.Data"
          border
          empty-text="暂无数据..."
          :header-cell-style="{ background: 'rgba(var(--themecolor),0.75)', color: '#ffffff' }"
          style="width: 100%"
          v-loading="contentLoading"
        >
          <el-table-column align="center" label="院校" width="220">
            <template slot-scope="props">
              <div class="hover-color" @click="toUniversityDetails(props.row)">
                {{ props.row.SchoolName }}
              </div>
              <div v-if="props.row.SchoolCode">
                院校代码：{{ props.row.SchoolCode }}
              </div>
              <!-- <div v-if="props.row.GroupCode">
                专业组代码：{{ props.row.GroupCode }}
              </div> -->
            </template>
          </el-table-column>
          <el-table-column v-if="searchType === 1" align="center" label="专业名称" width="140">
            <template slot-scope="props">
              <div class="hover-color" @click="toSpecialityDetails(props.row)">
                {{ props.row.EnrollmentSpecialty }}
              </div>
              <div v-if="props.row.Code">代码：{{ props.row.Code }}</div>
              <div v-if="props.row.ExamCondition">
                {{ props.row.ExamCondition }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="Year" label="年份" />
          <el-table-column align="center" prop="ScoreLow" label="最低分" />
          <el-table-column align="center" prop="ScoreAvg" label="平均分" />
          <el-table-column
            align="center"
            prop="RankLow"
            label="最低位次"
            width="160"
          />
          <el-table-column
            align="center"
            prop="ScoreDiff"
            label="线差"
            width="120"
          />
          <el-table-column
            align="center"
            prop="Persons"
            width="80"
            label="录取人数"
          />
          <el-table-column
            align="center"
            prop="BatchName"
            width="120"
            label="批次"
          />
        </el-table>
      </div>
      <div v-if="authorization">
        <el-pagination
          v-show="mainData.Total > 0"
          style="text-align: center"
          :page-size="10"
          background
          layout="prev, pager, next"
          :total="mainData.Total"
          :current-page.sync="query.PageIndex"
          @current-change="turnPage"
        />
      </div>
      <div v-else>
        <member-limit></member-limit>
      </div>
    </div>
    <info-dialog
      ref="infoDialog"
      @getEnrollDataListData="getEnrollDataListData"
    ></info-dialog>
  </div>
</template>

<script>
import API from '@/api/config'
import { mapGetters } from "vuex";
import { getStore } from '@/utils/utils'
// import { LineSpecialtyScoreList, LineSchooScoreList } from "@/api/enrollData";

// import { OrganizationProvince } from "@/api/student";
import ConditionScreen from "./local-components/ConditionScreen";
import InfoDialog from "./local-components/InfoDialog";
import MemberLimit from '@/views/choose-wish/local-components/MemberLimit.vue';

export default {
  name: "enroll-plan",
  components: {
    ConditionScreen,
    InfoDialog,
    MemberLimit
  },
  data() {
    return {
      mainData: [],
      schoolName: "",
      infoData: {
        // Year: new Date().getFullYear(),
      },
      //新高考状态参数
      isNewExam: false,
      newExamActive: false,

      contentLoading: false,
      //查询参数
      query: {
        ProvinceId: "",
        Year: new Date().getFullYear(),
        SubjectType: "",
        SelectSubject: "",
        PageIndex: 1,
        PageSize: 10,
      },
      // 年份数据配置
      YearData: [
        {
          name: new Date().getFullYear(),
          value: new Date().getFullYear() + "",
        },
        {
          name: new Date().getFullYear() - 1,
          value: new Date().getFullYear() - 1 + "",
        },
        {
          name: new Date().getFullYear() - 2,
          value: new Date().getFullYear() - 2 + "",
        },
        {
          name: new Date().getFullYear() - 3,
          value: new Date().getFullYear() - 3 + "",
        },
      ],
      searchType: 0,

      authorization: true,
    };
  },
  computed: {
    ...mapGetters(["token"]),
  },
  mounted() {
    this.initialize();
    this.$globalEventBus.$on("getEnrollDataListData", () => {
      
      if (this.$refs.conditionScreen) {
        this.$refs.conditionScreen.getquerySearchBatchList();
        this.getEnrollDataListData();
      }
    });

    // 权限
    this.$store.dispatch('user/allowMember', ['DataQuery']).then(flag => {
      this.authorization = flag
    })
  },
  methods: {
    //screenParameters
    screenParameters(parameters) {
      this.searchType = parameters.searchType;
      this.query.SchoolIdList = parameters.SchoolIdList;
      this.query.SchoolProvinceIdList = parameters.SchoolProvinceIdList;
      this.query.SchoolCategoryIdList = parameters.SchoolCategoryIdList;
      this.query.SchoolTagList = parameters.SchoolTagList;
      this.query.BatchIdList = parameters.BatchIdList;
      this.query.SchoolNature = parameters.SchoolNature||null;
      this.query.PageIndex = 1;
      this.getMainData().then((_) => {
        // callback()
      });
    },
    modifyInfo() {
      this.$refs.infoDialog.show();
    },
    //get data from search school
    searchSchool(id) {
      this.query.SchoolProvinceIdList = [];
      this.query.SchoolCategoryIdList = [];
      this.query.SchoolTagList = [];
      this.query.SchoolNature = "";
      this.query.SchoolId = id;
      this.query.PageIndex = 1;
      this.contentLoading = true;
      this.getMainData().then((_) => {
        this.contentLoading = false;
      });
    },
    //查看对应专业
    toSpecialityDetails(query) {
      if (query.SpecialtyCode.length === 4) {
        this.$router.push({
          path: "/specialty-list",
          query: {
            CategoryCode: query.SpecialtyCode,
            CategoryName: query.EnrollmentSpecialty,
          },
        });
      } else if (query.SpecialtyCode.length === 6) {
        this.$router.push({
          path: "/specialty-detail",
          query: { SpecialtyCode: query.SpecialtyCode },
        });
      }
    },
    // 查看大学
    toUniversityDetails(query) {
      this.$router.push({
        path: "/university-detail",
        query: { id: query.SchoolId },
      });
    },
    //翻页
    turnPage(page) {
      this.query.PageIndex = page;
      this.getMainData();
    },
    //选择年份 科目 招生方向
    selectParameters() {
      this.query.PageIndex = 1;
      this.getMainData();
    },
    //初始化数据
    initialize() {
      let temp = getStore('OrgProvince')
      if(temp) {
        this.infoData.ProvinceName = JSON.parse(temp).ProvinceName
        this.query.ProvinceId = JSON.parse(temp).ProvinceId
        this.getInfoParameters();

      } else {
        this.$message.error('未获取到省份信息！')
      }
    },
    //获取缓存数据
    getInfoParameters() {
      return new Promise((resolve) => {
        this.$getStorage("enrollDataInfo")
          .then((res) => {
            // this.infoData = res;
            this.infoData = Object.assign(res, this.infoData)
            
            // this.query.ProvinceId = res.ProvinceId;
            this.query.Year = res.Year;
            this.query.SelectSubject = res.SelectSubject || null;
            this.query.SubjectType = Number(res.SubjectType) || null;
            this.query.BatchIdList = []
            this.query.SchoolIdList = []
            this.query.SchoolTagList = []
            this.query.SchoolCategoryIdList = []
            this.query.SchoolProvinceIdList = []
            this.getMainData().then((_) => {
              resolve();
            });
          })
          .catch((_) => {
            this.initializeProvince().then((_) => {
              this.getMainData().then((_) => {
                resolve();
              });
            });
          });
      });
    },
    //get initialize province data
    initializeProvince() {
      return new Promise((resolve) => {
        //获取省份
        API.Public.ProvinceList().then((provinceData) => {
          // this.infoData.ProvinceName = provinceData.data[0].ProvinceName;
          // (this.query.ProvinceId = provinceData.data[0].ID),
            this.$store.dispatch("user/judgeNewExam", {
                id: provinceData.data[0].ID,
                year: new Date().getFullYear(),
              })
              .then((examStatus) => {
                if (examStatus.IsNewExam) {
                  this.$delete(this.query, "SubjectType");
                  if (examStatus.RuleList.length > 1) {
                    this.newExamActive = true;
                    this.$set(this.query, "SelectSubject", "物理");
                    this.infoData.typeName = "物理";
                    this.query.SelectSubject = "物理";
                  } else {
                    this.newExamActive = false;
                    this.query.SelectSubject = "";
                    this.infoData.typeName = "";
                    this.query.SelectSubject = "";
                  }
                } else {
                  this.$delete(this.query, "SelectSubject");
                  this.$set(this.query, "SubjectType", 1);
                  this.infoData.typeName = "文科";
                  this.query.SubjectType = 1;
                }
                resolve();
              });
        });
      });
    },
    getEnrollDataListData() {
      return new Promise((resolve) => {
        this.$getStorage("enrollDataInfo").then((res) => {
          this.$set(this.infoData, "Year", res.Year);
          // this.$set(this.infoData, "ProvinceName", res.ProvinceName);
          this.$set(this.infoData, "typeName", res.typeName);
          // this.query.ProvinceId = res.ProvinceId;
          this.query.Year = res.Year;
          this.query.SelectSubject = res.SelectSubject;
          this.query.SubjectType = Number(res.SubjectType) || null;
          this.query.BatchIdList = [];
          this.getMainData().then((_) => {
            resolve();
          });
        });
      });
    },
    //获取列表数据
    getMainData() {
      return new Promise((resolve) => {

        
        if (this.searchType === 1) {
          API.Public.LineSpecialtyScoreList(this.query).then((res) => {
            this.mainData = res.data;

            resolve();
          });
        } else {

          API.Public.LineSchooScoreList(this.query).then((res) => {
            this.mainData = res.data;

            resolve();
          });
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.main {
  width: 1100px;
  padding: 3rem 0;
  margin: auto;
  .head {
    .head-top {
      margin-bottom: 2rem;
      display: flex;
      .screen-item {
        display: flex;
        align-items: center;
        margin-right: 3rem;
        .title {
        }
        .text {
          color: rgba(var(--themecolor),1);
        }
      }
    }
    .head-bottom {
      margin: 2rem 0;
      .box2 {
      }
    }
  }
  .content {
    .content-table {
      margin-bottom: 2rem;
      .hover-color {
        transition: all 0.2s linear;
        cursor: pointer;
        &:hover {
          color: rgba(var(--themecolor),1);
        }
      }

      ::v-deep .el-table {
        margin-left: 0;
      }
    }
  }
}
</style>
<style lang="less">
.el-button--primary{
  background: rgba(var(--themecolor),0.75);
  border-color: rgba(var(--themecolor),0.75);
}
.el-button--primary:focus, .el-button--primary:hover{
  background: rgba(var(--themecolor),1);
  border-color: rgba(var(--themecolor),1);
}
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: rgba(var(--themecolor),0.75);
}
.el-pagination.is-background .el-pager li:not(.disabled):hover{
  color: rgba(var(--themecolor),0.75);
  background-color: rgba(var(--themecolor),0.2);
}
.el-pagination.is-background .el-pager li:not(.disabled).active:hover{
  color: #FFF;
  background-color: rgba(var(--themecolor),0.75);
}
.el-pagination.is-background .btn-next:hover, .el-pagination.is-background .btn-prev:hover, .el-pagination.is-background .el-pager li:hover{
  background-color: rgba(var(--themecolor),0.2);
}
</style>